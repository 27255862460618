.main-loader {
  display: flex;
  gap: 3px;
  align-items: flex-end;
}

.main-leaf {
  width: 10px;
  height: 30px;
  background-color: rgba(0, 0, 0); /* Black */
  clip-path: polygon(0% 0%, 100% 30%, 100% 100%, 0% 100%);
  animation: color1 1200ms infinite;
  animation-delay: 800ms;
}

.main-leaf:nth-child(2) {
  width: 15px;
  height: 40px;
  animation: color2 1200ms infinite;
  background-color: rgba(212, 99, 66); /* Orange */
  clip-path: polygon(50% 0%, 100% 20%, 100% 100%, 0% 100%, 0% 20%);
  animation-delay: 400ms;
}

.main-leaf:nth-child(1) {
  animation: color3 1200ms infinite;
  background-color: rgba(128, 128, 128); /* Gray */
  clip-path: polygon(0% 100%, 100% 100%, 100% 0%, 0% 30%);
  animation-delay: 0ms;
}

@keyframes color1 {
  from {
    background-color: rgba(128, 128, 128, 0.3);
  }
  to {
  }
}

@keyframes color2 {
  from {
    background-color: rgba(212, 99, 66, 0.3);
  }
  to {
  }
}

@keyframes color3 {
  from {
    background-color: rgba(0, 0, 0, 0.3);
  }
  to {
  }
}
