.flex-1 {
  flex: 1;
}

.welcome-section {
  background-color: #ffffff;
  border-radius: 12px;
  padding: 32px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.welcome-title h1 {
  font-size: 28px;
  font-weight: 600;
  color: #231f20;
}

.welcome-title p {
  color: #93918d;
  font-size: 16px;
}

.d-search-bar {
  position: relative;
  background-color: #f4f3ef;
  border-radius: 8px;
  padding: 12px 16px;
}

.d-search-bar .search-icon {
  position: relative;
  top: 0;
  left: 0;
}

.d-search-bar input {
  border: none;
  background: transparent;
  outline: none;
  color: #1a1a1a;
  width: 100%;
}

/* Summary Cards */
.summary-card {
  border-radius: 12px;
  flex: 1;
  text-decoration: none;
  background-color: #f4f3ef;
  color: #ef6b3e;
  gap: 12px;
  border: 0;
  transition: all 0.2s ease;
}
.summary-card:hover {
  transform: translateY(-3px);
  background-color: rgba(239, 107, 62, 0.1);
  color: #ef6b3e;
}
.summary-card:hover .card-icon {
  color: #1a1a1a;
}

.card-icon {
  background-color: #ffffff;
  border-radius: 50%;
  width: 48px;
  height: 48px;
  color: #ef6b3e;
  transition: all 0.2s ease;
}

.card-value {
  font-size: 24px;
  font-weight: 600;
  color: #1a1a1a;
}

.card-label {
  font-size: 14px;
  color: #93918d;
}

.card {
  background-color: #ffffff;
  border-radius: 12px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
}

.card-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px 24px;
  border-bottom: 1px solid #f4f3ef;
  background-color: transparent;
}

.card-header h2 {
  font-size: 18px;
  font-weight: 600;
  color: #231f20;
  margin: 0;
}

.button {
  background: none;
  border: none;
  cursor: pointer;
  color: #93918d;
  font-size: 18px;
  outline: none;
  margin: 0;
}

.more-button:hover {
  color: #1a1a1a;
  transition: color 0.3s ease;
}

.add-button {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  background-color: #ef6b3e;
  color: white;
  transition: transform 0.3s ease;
}

.add-button:hover {
  color: white !important;
  transform: rotate(90deg);
}

/* Recent Chats */
.chat-item {
  border-bottom: 1px solid #f4f3ef;
}

.chat-item:last-child {
  border-bottom: none;
}

.chat-name {
  font-weight: 600;
  color: #1a1a1a;
  font-size: 14px;
  margin-bottom: 4px;
}

.chat-message {
  color: #93918d;
  font-size: 13px;
}

.chat-meta {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}

.chat-time {
  color: #93918d;
  font-size: 12px;
}

.unread-count {
  background-color: #ef6b3e;
  color: white !important;
  width: 25px;
  height: 25px;
  border-color: white !important;
  font-size: 11px;
  font-weight: 600;
}

/* Quick Actions */
.action-grid {
  display: grid;
  gap: 16px;
}

.action-item {
  text-decoration: none;
  background-color: #f4f3ef;
  border-radius: 8px;
  color: #ef6b3e;
  gap: 12px;
  border: 0;
  transition: all 0.2s ease;
}

.action-item:hover {
  transform: translateY(-3px);
  background-color: rgba(239, 107, 62, 0.1);
  color: #ef6b3e;
}

.action-item span {
  font-size: 13px;
  font-weight: 500;
  color: #1a1a1a;
}

.event-list {
  padding: 16px 24px;
}

.event-item {
  border-bottom: 1px solid #f4f3ef;
}

.event-item:last-child {
  border-bottom: none;
}

.event-date {
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #f4f3ef;
  border-radius: 8px;
  padding: 8px;
  min-width: 48px;
  margin-right: 16px;
}

.event-day {
  font-size: 18px;
  font-weight: 600;
  color: #1a1a1a;
}

.event-month {
  font-size: 12px;
  color: #93918d;
}

.event-title {
  font-size: 15px;
  font-weight: 500;
  color: #1a1a1a;
  margin-bottom: 4px;
}

.event-info {
  font-size: 13px;
  color: #93918d;
  margin-bottom: 2px;
}

.event-time {
  font-size: 12px;
  color: #93918d;
}

.event-badge {
  font-size: 12px;
  padding: 4px 12px;
  border-radius: 16px;
  text-transform: capitalize;
  font-weight: 500;
}

.inspection {
  background-color: #f3f4f6;
  color: rebeccapurple;
}

.lease {
  background-color: #f4f3ef;
  color: #231f20;
}

.maintenance {
  background-color: #ffedd5;
  color: #e05a2d;
}

.completed {
  background-color: #f4f3ef;
  color: #059669;
}

.tenant {
  background-color: #f8f9fa;
  color: #4338ca;
}

.db-list-item {
  border-bottom: 1px solid rgba(0, 0, 0, 0.05);
}

.db-list-item:last-child {
  border-bottom: none;
}
/* ================================ */
/* DATE FORMATS                    */
/* ================================ */
.trip-date {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: #f4f3ef;
  border-radius: 8px;
  padding: 8px;
  min-width: 48px;
  text-align: center;
}

.date-day {
  font-size: 18px;
  font-weight: 600;
  color: #1a1a1a;
  line-height: 1.2;
}

.date-month {
  font-size: 12px;
  color: #93918d;
  text-transform: uppercase;
}

/* ================================ */
/* TENANT & TRIP INFO              */
/* ================================ */
.tenant-info,
.trip-info {
  flex: 1;
  min-width: 0;
}

.tenant-name,
.trip-route {
  font-size: 15px;
  font-weight: 500;
  color: #1a1a1a;
  margin-bottom: 4px;
}

.tenant-unit,
.trip-property {
  font-size: 13px;
  color: #93918d;
  margin-bottom: 2px;
}

/* ================================ */
/* PAYMENT INFO                    */
/* ================================ */
.payment-info,
.trip-stats {
  min-width: 80px;
  text-align: right;
  margin-right: 16px;
}

.amount {
  font-size: 16px;
  font-weight: 600;
}

.amount.overdue {
  color: darkred;
}

.amount.received {
  color: #27ae60;
}

.trip-stats .miles {
  font-size: 14px;
  color: #93918d;
  margin-bottom: 4px;
}

.trip-stats .amount {
  color: #ef6b3e;
}

/* ================================ */
/* STATUS TAGS                     */
/* ================================ */
.status-tag,
.payment-method {
  padding: 6px 12px;
  border-radius: 12px;
  font-size: 12px;
  font-weight: 500;
  min-width: 90px;
  text-align: center;
  white-space: nowrap;
}

.status-tag.overdue {
  background-color: rgba(239, 107, 62, 0.1);
  color: #93918d;
}

.payment-method {
  background-color: #f4f3ef;
  color: #93918d;
}

/* MILEAGE SUMMARY */
.summary-item {
  background-color: #f3f4f6;
}

/* ================================ */
/* DONUT CHART LAYOUTS             */
/* ================================ */
.db-chart-group {
  display: flex;
  justify-content: center;
  gap: 24px;
  margin: 0;
}

.chart-container {
  position: relative;
  width: 135px;
  height: 135px;
  transition: transform 0.3s ease;
}

.chart-container:hover {
  transform: scale(1.02);
}

.chart-center {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
  background-color: rgba(255, 255, 255, 0.8);
  height: 60%;
  aspect-ratio: 1;
  border-radius: 9999px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
  transition: all 0.2s ease;
  z-index: 10;
}

.chart-container:hover .chart-center {
  box-shadow: 0 3px 8px rgba(0, 0, 0, 0.15);
}

.chart-center .number {
  font-size: 18px;
  font-weight: 600;
  color: #1a1a1a;
  margin-bottom: 2px;
  z-index: 11;
}

.chart-center .label {
  font-size: 12px;
  color: #1a1a1a;
}

.chart-center .label a {
  transition: all 0.2s ease;
  padding: 2px 6px;
  border-radius: 8px;
  z-index: 11;
}

.chart-center .label a:hover {
  background-color: #f4f3ef;
  text-decoration: none;
}

/* ================================ */
/* STATS SECTIONS                  */
/* ================================ */
.db-stat-row {
  font-size: 14px;
  padding: 6px 8px;
  border-radius: 8px;
  transition: background-color 0.2s ease;
}

.db-stat-row:hover {
  background-color: #f4f3ef;
}

.db-dot {
  display: inline-block;
  width: 8px;
  height: 8px;
  border-radius: 50%;
  color: #ef6b3e;
  flex-shrink: 0;
}

.db-dot.secondary {
  color: #1a1a1a;
}

.db-dot.tertiary {
  color: #93918d;
}

.db-stat-value {
  font-size: 14px;
  font-weight: 500;
  color: #1a1a1a;
  background-color: #f4f3ef;
  padding: 4px 8px;
  border-radius: 12px;
  min-width: 35px;
  text-align: center;
  transition: background-color 0.2s ease;
}

.db-stat-row:hover .db-stat-value {
  background-color: rgba(239, 107, 62, 0.1);
}

/* ================================ */
/* RESPONSIVE STYLES               */
/* ================================ */
@media (max-width: 1200px) {
  .db-donut-layout {
    gap: 10px;
  }

  .db-chart-group {
    gap: 20px;
  }

  .chart-container {
    width: 120px;
    height: 120px;
  }
}

@media (max-width: 768px) {
  .db-chart-group {
    margin-bottom: 20px;
    justify-content: center;
  }

  .db-list-item {
    flex-wrap: wrap;
  }

  .status-tag,
  .payment-method {
    margin-top: 8px;
  }
}

@media (max-width: 576px) {
  .chart-container {
    width: 100px;
    height: 100px;
  }

  .db-chart-group {
    gap: 10px;
  }
}

@media (min-width: 576px) {
  .action-grid {
    grid-template-columns: repeat(2, 1fr);
  }
}
@media (min-width: 768px) {
  .action-grid {
    grid-template-columns: repeat(3, 1fr);
  }
}

@media (min-width: 992px) {
  .action-grid {
    grid-template-columns: repeat(2, 1fr);
  }
  .d-search-bar {
    min-width: 400px;
  }
}

@media (min-width: 1600px) {
  .action-grid {
    grid-template-columns: repeat(3, 1fr);
  }
}
