.lock-wrapper {
  position: relative;
  height: 100%; /* Ensures the wrapper takes full height of the parent */
  display: flex; /* Ensures the children are properly aligned */
  flex-direction: column; /* Maintains a vertical layout */
  justify-content: space-between; /* Keeps the content evenly spaced */
  border-radius: 12px !important;
}

.lock-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.7); /* Semi-transparent overlay */
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 2; /* Ensures the lock icon is above the content */
  pointer-events: none; /* Prevents interaction with the overlay */
}

.lock-icon {
  font-size: 2rem;
  color: white; /* Icon color */
}

.locked-content {
  opacity: 0.5; /* Dim the locked content */
  pointer-events: none; /* Prevent interaction with the locked content */
}

.lock-wrapper .card-body {
  z-index: 1; /* Ensures content stays below the overlay */
}

.no-access {
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.5);
  backdrop-filter: blur(5px);
  display: flex;
  justify-content: center;
  align-items: center;
  pointer-events: none;
  z-index: 1;
}