.my-card {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;
  max-height: 100px;
  border: 1px solid #c8c8c824;
  border-radius: 3px;
  padding: 5px;
}

.my-card:hover {
  background-color: #f6f6f6f5;
}

.small-text {
  font-size: 14px;

  color: #10182866;
}

.large-text {
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
  color: #101828;
}

.profile {
  margin-right: 20px;
}
.profile-img {
  width: 48px;
  height: 48px;
  border-radius: 50%;
  border: 1px solid #c8c8c8;
}
.row-card {
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
  justify-content: space-between;
  background-color: #fff;
  border-bottom: 1px solid #d1d1d196;
  /* border-radius: 5px; */
  /* box-shadow: 0 0 10px rgba(0, 0, 0, 0.1); */
  padding: 15px;
}

.row-card:hover {
  background-color: #f8f4f4;
}

.name {
  font-size: 1rem;
  font-weight: 500;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 200px;
  color: #333;
  text-wrap: nowrap;
}
