.custom-tree-select
  .ant-select:not(.ant-select-disabled):hover
  .ant-select-selector {
  border-color: #ef6b3e !important;
}

.custom-tree-select .ant-select-focused .ant-select-selector {
  border-color: #ef6b3e !important;
  box-shadow: 0 0 0 2px rgba(239, 107, 62, 0.2) !important;
}

.custom-tree-select .ant-select-selection-placeholder {
  color: rgba(0, 0, 0, 0.5) !important;
}
