.payable-overview {
  --color-orange: #ef6b3e;
  --color-gray-100: #e6e6e626;
  --color-gray-200: #667085;
  --color-gray-300: #667085;
  --color-dark-100: #344054;
  --color-dark-200: #101828;
}

.center-fit > * {
  width: fit-content;
  margin: auto;
}


.payable-overview-btn {
  font-size: 18px;
  font-weight: 500;
}

.payable-overview-download-btn,
.payable-overview-download-btn:hover,
.payable-overview-download-btn:active {
  background-color: var(--color-orange) !important;
  color: #fff !important;
  border-color: var(--color-orange) !important;
}

.payable-overview-print-btn,
.payable-overview-print-btn:hover {
  background-color: transparent !important;
  border-color: currentColor;
  color: var(--color-gray-300) !important;
}

.payable-overview-invoice-card {
  background-color: var(--color-gray-100);
  border-radius: 12px;
  position: relative;
}

.payable-overview-ribbon {
  --size: 1.2rem;
  position: absolute;
  left: calc(-1 * var(--size));
  top: 1rem;
  padding: 0.5rem 2rem 0.5rem 2rem;
  background-color: var(--color-orange);
  color: #fff;
}

.payable-overview-ribbon::after {
  content: "";
  position: absolute;
  top: 100%;
  left: 0;
  transform: translateY(-50%);
  border-top: var(--size) solid transparent;
  border-bottom: var(--size) solid transparent;
  border-right: var(--size) solid var(--color-orange);
}

.payable-divider {
  width: calc(100% - 6rem);
  margin: auto;
  border: 1px solid #d1d1d1;
}


.payable-overview-invoice-id {
  color: #101828;
}

.payable-overview-invoice-id span {
  color: var(--color-dark-200);
}

.payable-overview-invoice-from-icon {
  width: 140px;
  height: 140px;
  object-fit: contain;
}

.payable-overview-from-person > * {
  font-size: 20px;
  font-weight: 500;
  color: var(--color-gray-200);
  margin-bottom: 5px;
  font-family: Montserrat;
}

.payable-overview-from-person :last-child {
  margin-bottom: 0;
}

.payable-overview-invoice-field {
  display: flex;
  flex-direction: column;
}

.payable-overview-invoice-field > :nth-child(1) {
  color: var(--color-gray-200);
  margin-bottom: 6px;
  font-weight: 500;
}

.payable-overview-invoice-field > :nth-child(2) {
  font-size: 20px;
  font-weight: 500;
  color: var(--color-dark-100);
}

.payable-overview-invoice-property-name
  .payable-overview-invoice-field
  > :nth-child(2) {
  width: 20ch;
}

.payable-overview-due-date {
  border-radius: 4px;
  padding: 3px;
  background-color: #d1d1d145;
  margin-top: 5px;
  color: var(--color-dark-100);
  width: fit-content;
}

/* payable overview table */
.payable-overview-table-container {
  overflow: auto;
}

.payable-overview-table {
  border-collapse: collapse;
  min-width: 900px;
  overflow: auto;
}

.payable-overview-table thead {
  background-color: #f2f4f7;
}
.payable-overview-table :where(tbody, tfoot, tr) {
  background-color: transparent !important;
  border: 0;
}

.payable-overview-table td:nth-child(1) {
  width: 25%;
}
.payable-overview-table td:nth-child(2) {
  width: 35%;
}
.payable-overview-table td:nth-child(3) {
  width: 20%;
}
.payable-overview-table td:nth-child(4) {
  width: 20%;
}

.payable-overview-table :where(td, th) {
  padding: 1rem 2rem;
}

.payable-overview-table td {
  font-size: 17px;
}

.payable-overview-table-body td {
  border-bottom: 2px solid #d1d1d1;
  color: var(--color-dark-200);
}

.payable-overview-table-footer {
  border: 0;
}

.payable-overview-table-footer tr:not(:last-child) td:nth-child(2) {
  color: var(--color-gray-200);
  font-weight: 400;
}

.payable-overview-table-footer tr:not(:last-child) td:nth-child(3) {
  color: var(--color-dark-200);
  font-weight: 400;
}

.payable-overview-footer p {
  color: var(--color-orange);
  font-weight: 500;
  margin: 0;
}

@media (width >1200px){

  .payable-overview-invoice-from-item > :nth-child(1) {
    flex: 60%;
  }
  .payable-overview-invoice-from-item > :nth-child(2) {
    flex: 40%;
  }

  .payable-overview-property-name{
    width: 75% !important;
  }
}
