.setting-navbar-main-container {
  height: 87vh;
  width: 362px;
  overflow-y: scroll;
  overflow-x: hidden;
}

.setting-info-container {
  height: calc(100vh - 245px);
  padding-right: 10px;
  /* border: 1px solid #e5e5e5; */
  overflow-y: scroll;
  overflow-x: hidden;
}

.setting-info-container::-webkit-scrollbar {
  width: 0.3em;
}

.setting-info-container::-webkit-scrollbar-thumb {
  background-color: #888;
  border-radius: 10px;
}

.setting-navbar-main-container::-webkit-scrollbar {
  width: 0.3em;
}

.setting-navbar-main-container:hover::-webkit-scrollbar-thumb {
  background-color: #888;
  border-radius: 10px;
}


.setting-useinfo-container {
  height: calc(100vh - 310px);
  padding-right: 10px;
  /* border: 1px solid #e5e5e5; */
  overflow-y: scroll;
  overflow-x: hidden;
}

.setting-useinfo-container::-webkit-scrollbar {
  width: 0.3em;
}

.setting-useinfo-container::-webkit-scrollbar-thumb {
  background-color: #888;
  border-radius: 10px;
}


