/* Push Notification */
.push-notification-container {
    background-color: #fff;
    color: #333;
    padding: 10px 20px;
    position: fixed;
    display: flex;
    flex-direction: column;
    gap: 5px;
    bottom: 85px;
    right: 20px;
    width: 370px;
    border-radius: 10px;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
    z-index: 11;
    cursor: pointer;

    @media (max-width: 768px) {
        bottom: 150px;
    }
}

.notification-content-container {
    display: flex;
    gap: 10px
}

.push-notification-img-container {
    display: flex;
    align-items: center;
}

.push-notification-img-container img {
    width: 50px;
    height: 50px;
    border-radius: 50%;
    object-fit: cover;
}

.push-notification-title-container {
    margin-bottom: 5px;
}

.push-notification-title{
    font-weight: bold;
}

.push-notification-content {
    font-size: 0.9em;
}

.notification-time {
    display: flex;
    justify-content: end;
    font-size: 11px;
}
