.collapse-container {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin-top: 20px;
  padding: 10px;
  width: 100%;
  outline: 1px solid #d1d1d1;
  border-radius: 5px;
}

.dropdown-container {
  padding: 16px;
  margin-top: 20px;
  border-radius: 10px;
  background-color: #fff;
}

.dropdown-border {
  border: 1px solid rgb(233, 233, 233);
}

.dropdown-shadow {
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.3); /* Horizontal offset, Vertical offset, Blur radius, Spread radius, Color */
}

.button {
  display: inline-block;
  width: auto;
  margin-top: 1rem;
  margin-left: 1rem;
  padding: 5px;
  outline: 1px solid #ef6b3e;
  border-radius: 5px;
  cursor: pointer;
}

.expandable-container {
  display: grid;
  overflow: hidden;
  grid-template-rows: 0fr;
  transition: grid-template-rows 0.3s ease-in;
}

.expandable-container.open {
  grid-template-rows: 1fr;
}

/* Renamed .expander-content to .expandable-content */
.expandable-content {
  min-height: 0;
  transition: visibility 0.3s ease-in;
  visibility: hidden;
}

.expandable-content.visible {
  visibility: visible;
}