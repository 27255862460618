.autocomplete-wrapper{
  position: relative;
  width: 100%;
}

.autocomplete-container {
  position: absolute;
  width: 100%;
  left: 0;
  top: 100%;
  z-index: 50;
  padding-top: 0.5rem;
}

.autocomplete-list {
  background-color: white;
  border-radius: 0.375rem;
  border: 1px solid #e5e7eb;
  list-style: none;
}

.autocomplete-item {
  border-bottom: 1px solid #e5e7eb;
  padding-left: 1rem;
  padding-right: 1rem;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  cursor: pointer;
  font-size: 15px;
}

.autocomplete-item:last-child {
  border-bottom: none;
}
