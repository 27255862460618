.modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 99999;
  }
  
  .modal-dialog {
    max-width: 500px;
    margin: 0 auto;
  }
  
  .modal-content {
    border-radius: 10px;
  }
  
  .modal-title {
    font-weight: 600;
  }
  
  .btn-block {
    width: 100%;
  }
  