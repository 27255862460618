.selectField {
  border-radius: 5px;
  border-width: 1px;
}

.my-dropdown {
  box-sizing: border-box;
  margin-top: -8px;
  font-size: 14px;
  width: 100%;
  font-family: montserrat;
  position: absolute;
  top: 55px;
  z-index: 100049;
  overflow-y: scroll;
  max-height: 270px;
  background-color: #ffffff;
  border-radius: 5px;
  outline: 1px solid #d1d1d1;
  box-shadow: 0 6px 16px 0 rgba(0, 0, 0, 0.08),
    0 3px 6px -4px rgba(0, 0, 0, 0.12), 0 9px 28px 8px rgba(0, 0, 0, 0.05);
}
