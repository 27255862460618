.custom-sidebar-menu .ant-menu-sub {
    background-color: #231F20 !important; /* Overrides the background color */
  }
  
  .custom-sidebar-menu .ant-menu-dark.ant-menu-inline .ant-menu-sub {
    background-color: #231F20 !important; /* Ensures inline submenus follow your color */
  }
  
  .custom-sidebar-menu .ant-menu-dark .ant-menu-inline .ant-menu-sub {
    background-color: #231F20 !important; /* Extra specificity to override default styling */
  }
  
  .custom-sidebar-menu .ant-menu-item {
    margin-left: 0 !important; 
  }
  .custom-sidebar-menu .ant-menu-item {
    display: flex;
    align-items: center;
  }
  
  .custom-sidebar-menu .ant-menu-item div {
    display: flex;
    align-items: center;
  }
  
  /* For SubMenu title */
  .custom-sidebar-menu .ant-menu-submenu-title {
    display: flex;
    align-items: center;
  }
  
  .custom-sidebar-menu .ant-menu-submenu-title div {
    display: flex;
    align-items: center;
  }
  .ant-menu-submenu-title{
    padding: 0px !important;
    margin: 0px !important;
    margin-bottom: 10px !important;
  }
  .border-fucking{
    border-top-left-radius: 50px !important;
  }


  #sidebar-menu {
    overflow-y: scroll; /* Enables vertical scrolling */
    scrollbar-width: none; /* For Firefox - Hides scrollbar */
    -ms-overflow-style: none; /* For Internet Explorer and Edge */
  }
  
  #sidebar-menu::-webkit-scrollbar {
    display: none; /* For Chrome, Safari, and Opera - Hides scrollbar */
  }
  
  
 
  
  