.owner-list-container {
  max-width: 800px;
}


.owner-item + .owner-item {
  border-top: 1px solid #dee2e6;
}

.profile-image {
  width: 48px;
  height: 48px;
  margin-right: 15px;
  border-radius: 50%;
  overflow: hidden;
}

.profile-image img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.user-info h6 {
  font-size: 16px;
  font-weight: 500;
}

.user-info p {
  font-size: 14px;
  color: #6c757d;
}

.ownership {
  display: flex;
  gap: 0.25em;
  justify-content: center;
  align-items: baseline;
  background-color: #f7f7f7;
  padding: 8px 16px;
  border-radius: 6px;
}

.ownership-label {
  display: block;
  font-size: 14px;
  color: #6c757d;
}

.ownership-value {
  display: block;
  font-size: 16px;
  font-weight: 500;
  color: #212529;
}
