/* Add these custom utility classes */
.bg-gray {
  background-color: #fafafa;
}
.text-gray {
  color: #667085;
}
.badge-no-access,
.badge-no-access:hover {
  background-color: #f3f4f6;
  color: #444444;
}
.badge-create-edit,
.badge-create-edit:hover {
  background-color: rgba(239, 107, 62, 0.1);
  color: #ef6b3e;
}

.badge-full-control,
.badge-full-control:hover {
  background-color: #ef6b3e;
  color: #ffffff;
}
.tab-button {
  background-color: #f3f4f6;
}
.tab-active-button,
.tab-active-button:hover {
  background-color: #ef6b3e;
  color: #ffffff;
}

.bg-green {
  background-color: #ecfdf3;
}
.text-green {
  color: #12b76b;
}

.bg-orange {
  background-color: #ffedd5;
}
.text-orange {
  color: #fca157;
}

.bg-red {
  background-color: #feefe2;
}

.text-red {
  color: #d92d1f;
}

.bg-gray-100 {
  background-color: #f3f4f6;
}

.text-purple {
  color: #6d28d9;
}

.bg-purple {
  background-color: #6d28d9;
}

.btn-white {
  background-color: #ffffff;
}

/* Override Bootstrap styles */
.form-select:focus {
  box-shadow: none;
  border-color: #6d28d9;
}

.btn:focus {
  box-shadow: none;
}

.modal{
  z-index: 9999;
}

.modal-backdrop {
  background-color: rgba(0, 0, 0, 0.5);
}

.table {
  --bs-table-hover-bg: transparent;
  border-collapse: collapse;
}
.table > :not(caption) > * > * {
  padding: 1rem;
}

.table-hover tbody tr:hover {
  background-color: none !important;
}

/* Add these styles to your existing CSS */
.table td {
  padding: 5rem 1.8rem;
  vertical-align: middle;
  border: 1px solid #e5e5e5;
}

.table tr:hover td {
  background-color: none !important;
}

.table tr:hover td[rowspan] {
  background-color: none !important;
}

/* Ensure the rowspan cells maintain their background */
.table td[rowspan] {
  /* border: 1px solid #f9fafb; */

  background-color: #f9fafb !important;
}

/* Add subtle transition for hover effects */
.table tr {
  transition: background-color 0.2s ease;
}

.top-badge {
  font-size: 14px !important;
}
/* Add these styles to your existing CSS */
.permission-tag {
  font-size: 0.875rem;

  transition: all 0.2s ease;
}

.noaccess-bg {
  background-color: #f3f4f6;
}
.permission-tag:hover {
  opacity: 0.9;
}

.cursor-pointer {
  cursor: pointer;
}

.hover-bg-light:hover {
  background-color: #f8f9fa;
}

.z-index-dropdown {
  z-index: 1000;
}

.bg-purple {
  background-color: #6f42c1;
}

.text-purple {
  color: #6f42c1;
}

/* Ensure the table cells have enough height for the dropdown */
.table td {
  padding-top: 0.75rem;
  padding-bottom: 0.75rem;
}

.custom-modal-overlay {
 
  z-index: 9999;
}

.custom-modal {
  max-width: 600px;
  margin: 0;
}

.modal-dialog {
  max-width: 100%;
  min-width: 600px;
  margin: 0;
  z-index: 99999999;
}

.modal-content {
  border-radius: 8px;
  width: 100%;
}

.modal-header,
.modal-footer {
  border-bottom: 0;
}

.modal-body {
  padding: 20px;
}

.modal-backdrop {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1050;
}

.badge {
  font-weight: normal;
  font-size: 0.875rem;
}

.btn-primary {
  background-color: #f97316;
  border-color: #f97316;
}

.btn-primary:hover {
  background-color: #ea580c;
  border-color: #ea580c;
}

.text-primary {
  color: #f97316 !important;
}

.bg-opacity-10 {
  --bs-bg-opacity: 0.1;
}
