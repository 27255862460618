.property-filter-container {
  position: fixed;
  z-index: 1000 !important;
  inset: 0;
  transform: translateX(-100%);
  transition: transform 0.3s;
}

.property-filter-container .overlay {
  position: fixed;
  inset: 0;
  background-color: rgb(0, 0, 0, 0);
  transition: background-color 1s;
}
.property-filter-wrapper::-webkit-scrollbar {
  width: 4px;
}

.property-filter-wrapper::-webkit-scrollbar-thumb {
  background: #eee;
}

.property-filter-wrapper {
  height: 100%;
  position: relative;
  z-index: 2;
  background-color: #fff;
  padding: 2rem;
}
.property-filter-container.visible {
  /* background-color: rgba(0, 0, 0, 0.4); */
  transform: translateX(0);
}
.property-filter-container.visible .overlay {
  background-color: rgb(0, 0, 0, 0.2);
}
.property-filter-container.visible .overlay {
  opacity: 1;
}

.property-filter-cancel-btn {
  display: block;
  margin-left: auto;
  width: 2rem;
  height: 2rem;
  border: 0;
  background-color: transparent;
  cursor: pointer;
}

@media (width >= 768px) {
  .property-filter-wrapper {
    max-width: 500px;
  }
}

@media (width >=992px) {
  .overlay {
    display: none;
  }
  .property-table-wrapper {
    width: 100%;
    transition: margin 0.3s;
  }

  .property-table-wrapper.expand {
    margin-left: -400px;
  }
  .property-filter-container {
    position: relative;
  }

  .property-filter-wrapper {
    padding: 1rem;
    width: 400px;
    height: calc(100vh - 289px);
    overflow: auto;
  }

  .property-filter-cancel-btn {
    display: none;
  }
}
