.find-local-professionals {
  --Tomato: #ef6b3e;
  --Zuez: #231f20;
  --Grey: #93918d;
  --Fantasy: #f4f3ef;
  --White: #ffffff;
  --RangoonGreen: #1a1a1a;
  --BlackEel: #444444;
  --BrightGrey: #374151;
  --Flame: #e05a2d;
  --WhiteSmoke: #f3f4f6;
  --SnowDrift: #f8f9fa;
  --PapayaWhip: #ffedd5;
}

.spfinder-container {
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 20px;
}

/* Hero Section */
.spfinder-hero {
  background-color: #fff;
  padding: 60px 0;
  text-align: center;
  border-bottom: 1px solid #e2e8f0;
}

.spfinder-title {
  font-size: 2.5rem;
  font-weight: bold;
  margin-bottom: 16px;
  color: #2d3748;
}

.spfinder-subtitle {
  color: #718096;
  max-width: 600px;
  margin: 0 auto 32px;
}

/* Search Bar */
.spfinder-search {
  display: flex;
  gap: 16px;
  max-width: 800px;
  margin: 0 auto;
}

.spfinder-search-input {
  flex: 1;
  position: relative;
}

.spfinder-search-input .icon {
  position: absolute;
  left: 16px;
  top: 50%;
  transform: translateY(-50%);
  color: #a0aec0;
}

.spfinder-search-input input {
  width: 100%;
  padding: 12px 12px 12px 40px;
  border: 1px solid #e2e8f0;
  border-radius: 8px;
  font-size: 1rem;
}

.spfinder-search-input input:focus {
  outline: none;
  border-color: var(--Tomato);
  box-shadow: 0 0 0 3px rgba(237, 137, 54, 0.1);
}

.spfinder-btn-primary {
  background-color: var(--Tomato);
  color: white;
  border: none;
  padding: 12px 32px;
  border-radius: 8px;
  cursor: pointer;
  font-weight: bold;
  transition: background-color 0.3s;
}

.spfinder-btn-primary:hover {
  background-color: var(--Flame);
}

.spfinder-btn-primary:disabled {
  background-color: #cbd5e0;
  cursor: not-allowed;
}

/* Main Content Layout */
.spfinder-content {
  display: flex;
  gap: 32px;
  padding: 32px 0;
}

/* Sidebar Filters */

.spfinder-filter-container {
  position: fixed;
  overflow-y: auto;
  z-index: 1001;
  left: 0;
  top: 0;
  bottom: 0;
  width: 100%;
  transform: translateX(-100%);
  will-change: transform;
  transition: transform 0.25s ease-out;
  background-color: white;
  padding: 2rem;
}
.spfinder-filter-aside-header .close-button{
  border: 0;
  background-color: white;
  width: 32px;
  height: 32px;
  margin-left: auto;
  display: block;
}

.spfinder-filter-container.visible {
  transform: translateX(0);
}

.spfinder-filter-overlay {
  position: fixed;
  display: none;
  inset: 0;
  opacity: 0;
  background-color: rgba(0, 0, 0, 0.3);
  transform: translateX(-100%);
  transition: opacity 0.2s ease-in;
  z-index: 1000;
}

.spfinder-filter-overlay.visible {
  opacity: 1;
  transform: translateX(0);
}


.spfinder-filter-header {
  display: flex;
  align-items: center;
  gap: 8px;
  margin-bottom: 24px;
}

.spfinder-filter-header h2 {
  font-size: 1.25rem;
  font-weight: 600;
}

.spfinder-filter-section {
  margin-bottom: 24px;
}

.spfinder-filter-section h3 {
  font-size: 1rem;
  font-weight: 600;
  margin-bottom: 16px;
  color: #2d3748;
}

/* Category Group Styles */
.spfinder-category-group {
  margin-bottom: 10px;
  border: 1px solid #e2e8f0;
  border-radius: 6px;
  overflow: hidden;
}

.spfinder-group-header {
  display: flex;
  align-items: center;
  padding: 12px 15px;
  background-color: #f8f9fa;
  cursor: pointer;
  transition: background-color 0.2s;
}

.spfinder-group-header:hover {
  background-color: #f1f3f5;
}

.spfinder-group-header .icon {
  margin-right: 10px;
  color: var(--Tomato);
}

.spfinder-group-header span {
  flex: 1;
  font-weight: 500;
  font-size: 0.95rem;
}

.spfinder-arrow {
  transition: transform 0.3s;
}

.spfinder-category-group.active .spfinder-arrow {
  transform: rotate(180deg);
}

.spfinder-group-options {
  display: none;
  padding: 10px 15px;
  border-top: 1px solid #e2e8f0;
  background-color: white;
}

.spfinder-category-group.active .spfinder-group-options {
  display: block;
}

.spfinder-all-option {
  margin: 15px 0;
  padding: 0 5px;
}

/* Location Filter Styles */
.spfinder-location-form {
  margin-bottom: 16px;
}

.spfinder-input-wrapper {
  position: relative;
  margin-bottom: 12px;
}

.spfinder-input-wrapper .icon {
  position: absolute;
  left: 12px;
  top: 50%;
  transform: translateY(-50%);
  color: #a0aec0;
}

.spfinder-location-input {
  width: 100%;
  padding: 12px 12px 12px 36px;
  border: 1px solid #e2e8f0;
  border-radius: 8px;
  font-size: 0.95rem;
  transition: border-color 0.2s, box-shadow 0.2s;
}

.spfinder-location-input:focus {
  outline: none;
  border-color: var(--Tomato);
  box-shadow: 0 0 0 3px rgba(239, 107, 62, 0.1);
}

.spfinder-location-input::placeholder {
  color: #a0aec0;
}

.spfinder-clear-input {
  position: absolute;
  right: 12px;
  top: 50%;
  transform: translateY(-50%);
  background: none;
  border: none;
  color: #a0aec0;
  font-size: 1.2rem;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 20px;
  height: 20px;
  border-radius: 50%;
}

.spfinder-clear-input:hover {
  background-color: #f1f1f1;
  color: #718096;
}

/* Location action buttons */
.spfinder-location-actions {
  display: flex;
  gap: 8px;
  margin-bottom: 12px;
}

.spfinder-use-current {
  flex: 1;
  padding: 8px 12px;
  background: none;
  border: 1px solid #e2e8f0;
  border-radius: 6px;
  font-size: 0.85rem;
  color: #4a5568;
  cursor: pointer;
  transition: all 0.2s;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 6px;
}

.spfinder-use-current:hover:not(:disabled) {
  background-color: #f8f9fa;
  border-color: #cbd5e0;
}

.spfinder-use-current .icon {
  color: var(--Tomato);
}

.spfinder-apply-btn {
  padding: 8px 16px;
  background-color: var(--Tomato);
  border: none;
  border-radius: 6px;
  color: white;
  font-weight: 500;
  font-size: 0.85rem;
  cursor: pointer;
  transition: background-color 0.2s;
}

.spfinder-apply-btn:hover:not(:disabled) {
  background-color: var(--Flame);
}

.spfinder-apply-btn:disabled,
.spfinder-use-current:disabled {
  opacity: 0.6;
  cursor: not-allowed;
}

/* Error message */
.spfinder-error {
  color: #e53e3e;
  font-size: 0.85rem;
  margin-top: 8px;
}

/* Saved locations */
.spfinder-saved-locations {
  margin-bottom: 16px;
}

.spfinder-saved-title {
  font-size: 0.85rem;
  color: #718096;
  margin-bottom: 8px;
}

.spfinder-saved-list {
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
  margin-bottom: 12px;
}

.spfinder-saved-item {
  background: none;
  border: 1px solid #e2e8f0;
  border-radius: 16px;
  padding: 4px 12px;
  font-size: 0.85rem;
  color: #4a5568;
  cursor: pointer;
  transition: all 0.2s;
  display: flex;
  align-items: center;
  gap: 6px;
}

.spfinder-saved-item:hover {
  background-color: #f8f9fa;
  border-color: #cbd5e0;
}

.spfinder-saved-item .icon {
  color: #a0aec0;
  font-size: 0.75rem;
}

/* Distance options */
.spfinder-distance-options {
  margin-top: 20px;
}

.spfinder-distance-title {
  font-size: 1rem;
  font-weight: 600;
  margin-bottom: 16px;
  color: #2d3748;
}

/* Current search location display */
.spfinder-current-search {
  background-color: #f8f9fa;
  padding: 8px 12px;
  border-radius: 6px;
  font-size: 0.9rem;
  color: #4a5568;
  display: flex;
  align-items: center;
  gap: 6px;
}

.spfinder-current-search .icon {
  color: var(--Tomato);
}

/* Main Content Area */
.spfinder-list-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 24px;
  flex-wrap: wrap;
  gap: 12px;
}

.spfinder-list-header h2 {
  font-size: 1.5rem;
  color: #2d3748;
}

.spfinder-sort {
  padding: 8px 16px;
  border: 1px solid #e2e8f0;
  border-radius: 6px;
  background: white;
  cursor: pointer;
}

/* Service Professional Cards */
.spfinder-cards {
  display: flex;
  flex-direction: column;
  gap: 16px;
}

.spfinder-card {
  background: white;
  border-radius: 8px;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
  transition: box-shadow 0.3s;
}

.spfinder-card:hover {
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.spfinder-card-content {
  padding: 24px;
  display: flex;
  gap: 24px;
}

.spfinder-pro-img {
  width: 80px;
  height: 80px;
  border-radius: 8px;
  object-fit: cover;
}

.spfinder-card-info {
  flex: 1;
}

.spfinder-card-header {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  margin-bottom: 12px;
}

.spfinder-card-header h3 {
  font-size: 1.25rem;
  margin-bottom: 4px;
  color: #2d3748;
}

.spfinder-card-header .fa-check-circle {
  color: #48bb78;
  margin-left: 8px;
}

.spfinder-category {
  color: var(--Tomato);
  font-weight: 500;
}

.spfinder-location {
  display: flex;
  align-items: center;
  color: #4a5568;
  font-size: 0.9rem;
  margin-top: 4px;
}

.spfinder-location .icon {
  color: var(--Tomato);
  margin-right: 5px;
}

.spfinder-rating {
  display: flex;
  align-items: center;
  gap: 4px;
}

.spfinder-rating .fa-star {
  color: #f6e05e;
}

.spfinder-description {
  color: #718096;
  margin-bottom: 16px;
  line-height: 1.5;
}

.spfinder-description strong {
  color: #2d3748;
}

.spfinder-card-meta {
  display: flex;
  gap: 24px;
  color: #718096;
}

.spfinder-card-meta span {
  display: flex;
  align-items: center;
  gap: 6px;
}

.spfinder-card-meta .icon {
  color: #a0aec0;
}

/* Pagination */
.spfinder-pagination {
  display: flex;
  justify-content: center;
  gap: 8px;
  margin-top: 40px;
}

.spfinder-page-btn {
  padding: 8px 16px;
  border: 1px solid #e2e8f0;
  border-radius: 6px;
  background: white;
  cursor: pointer;
  transition: all 0.3s;
}

.spfinder-page-btn:hover {
  background: #f7fafc;
}

.spfinder-page-btn.spfinder-active {
  background: var(--Tomato);
  color: white;
  border-color: var(--Tomato);
}

/* Responsive Design */
@media (max-width: 1024px) {
  .spfinder-content {
    grid-template-columns: 240px 1fr;
    gap: 24px;
  }
}

@media (max-width: 768px) {
  .spfinder-content {
    grid-template-columns: 1fr;
  }

  .spfinder-search {
    flex-direction: column;
  }

  .spfinder-location-actions {
    flex-direction: column;
  }

  .spfinder-card-content {
    flex-direction: column;
    align-items: center;
    text-align: center;
  }

  .spfinder-card-header {
    flex-direction: column;
    align-items: center;
    gap: 12px;
  }

  .spfinder-card-meta {
    justify-content: center;
    flex-wrap: wrap;
  }
}

@media (width >=768px) {
  .spfinder-filter-container {
    width: 500px;
  }

  .spfinder-filter-overlay {
    display: block;
  }
}

@media (width >=1000px) {
  .spfinder-filter-aside-header {
    display: none;
  }
  .spfinder-main {
    transition: margin 0.25s ease-out;
  }
  .spfinder-main.expand {
    margin-left: -400px;
  }
  .spfinder-filter-container {
    position: sticky;
    top: 1rem;
    width: 400px;
    min-width: 400px;
    padding-top: 0;
    height: calc(100vh - 289px);
  }

  .spfinder-filter-overlay {
    display: none;
  }
}
