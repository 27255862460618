/* PaymentForm.css */
.form-label {
    font-weight: 500;
    color: #333;
  }
  
  .form-check-label {
    font-size: 0.9rem;
    color: #555;
  }
  
  .btn-primary {
    background-color: #ff6f61;
    border-color: #ff6f61;
    font-weight: bold;
  }
  
  .btn-primary:hover {
    background-color: #ff5b4a;
    border-color: #ff5b4a;
  }
  