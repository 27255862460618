.font-semibold {
  font-weight: 600;
}
.break-word {
  word-break: break-word;
}

.h-400 {
  height: 400px;
}
.h-200 {
  height: 200px;
}

.card-shadow {
  box-shadow: none;
}

.bg-gradient-to-top {
  background: linear-gradient(to top, rgba(0, 0, 0, 0.8), transparent);
}

.servpro-container {
  padding: 20px;
}

.servpro-stats-item {
  text-align: center;
}

.servpro-certification-item,
.serpro-service-item {
  padding: 15px;
  background-color: rgb(248, 249, 250);
  border-radius: 8px;
}
