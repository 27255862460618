body {
    margin: 0;
    background-color: #f8f9fa;
  }
  
  .toc-container {
    background-color: #ffffff;
    box-shadow: 2px 0 10px rgba(0, 0, 0, 0.1);
    height: 100vh;
    position: sticky;
    top: 0;
  }
  
  .toc-wrapper {
    padding: 2rem 1.5rem;
    height: 100vh;
    overflow-y: auto;
  }
  
  .toc-title {
    font-size: 1.5rem;
    font-weight: 600;
    color: #1a1a1a;
    margin-bottom: 1.5rem;
    padding-bottom: 1rem;
    border-bottom: 2px solid #e9ecef;
  }
  
  .toc-list {
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
  }
  
  .toc-item {
    padding: 0.75rem 1rem;
    border-radius: 8px;
    cursor: pointer;
    transition: all 0.2s ease;
    display: flex;
    align-items: flex-start;
    gap: 0.75rem;
    font-size: 0.9rem;
    color: #4a5568;
  }
  
  .toc-item:hover {
    background-color: #f8f9fa;
    color: #2b6cb0;
  }
  
  .toc-icon {
    margin-top: 4px;
    font-size: 0.8rem;
  }
  
  .main-content-wrapper {
    height: 100vh;
    background-color: #ffffff;
  }
  
  .main-content {
    height: 100vh;
    overflow-y: auto;
    padding: 3rem 4rem;
  }
  
  .policy-title {
    font-size: 2.5rem;
    font-weight: 700;
    color: #1a1a1a;
    margin-bottom: 3rem;
  }
  
  .content-section {
    margin-bottom: 4rem;
  }
  
  .content-section h2 {
    font-size: 1.5rem;
    font-weight: 600;
    color: #2d3748;
    margin-bottom: 1.5rem;
  }
  
  .content-section p {
    color: #4a5568;
    line-height: 1.7;
    font-size: 1rem;
  }
  
  /* Scrollbar Styling */
  ::-webkit-scrollbar {
    width: 6px;
  }
  
  ::-webkit-scrollbar-track {
    background: #f1f1f1;
  }
  
  ::-webkit-scrollbar-thumb {
    background: #c5c5c5;
    border-radius: 3px;
  }
  
  ::-webkit-scrollbar-thumb:hover {
    background: #a8a8a8;
  }
  
  /* Responsive Adjustments */
  @media (max-width: 991px) {
    .toc-container {
      height: auto;
      position: relative;
    }
    
    .toc-wrapper {
      height: auto;
      max-height: 300px;
    }
    
    .main-content {
      padding: 2rem 1.5rem;
    }
    
    .policy-title {
      font-size: 2rem;
      margin-bottom: 2rem;
    }
  }
  
  