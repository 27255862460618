.tags-container {
    display: flex;
    flex-wrap: wrap;
    gap: 5px;
    padding: 10px 10px;
    min-height: 20px; /* Increased initial height for better visibility */
    border: 1px solid #ced4da;
    border-radius: 4px;
    cursor: pointer;
    align-items: center;
    overflow: visible;

  }
  
  .expanded-view {
    margin-top: 8px;
    /* min-height: 60px; Expanded view */
  }
  
  .selected-tag {
    background-color: #FDE7E1;
    padding: 5px 10px;
    border-radius: 12px;
    font-size: 12px;
    display: flex;
    align-items: center;
    gap: 5px;
  }
  
  .remove-tag {
    cursor: pointer;
    font-weight: bold;
  }
  
  .placeholder-text {
    color: #6c757d;
  }
  
  .multi-chevron {
    margin-left: auto;
  }
  
  .my-dropdown {
    max-height: 250px;
    margin-top: 10px;
    /* overflow-y: auto; */
    border: 1px solid #ced4da;
    background: #fff;
    z-index: 9999999999 !important;
  }
  
  .dropdown-item-container {
    cursor: pointer;
    margin-bottom: 5px;
  }
  