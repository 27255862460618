.unit-info {
  margin-top: 16px;
  display: flex;
  flex-direction: column;
}

.title {
  font-size: 30px;
  font-weight: 600;
  font-family: "montserrat";
  color: #344054;
}

.address {
  font-size: 16px;
  font-weight: 400;
  font-family: "montserrat";
  color: #667085;
}
