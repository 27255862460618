.step-progress {
  --color-2: #ef6b3e;
  --color-1: #eaecf0;
  --step-count-size: 2.5rem;

  display: flex;
  align-items: center;
  justify-content: center;
}

.hidden {
  display: none;
}

.cursor-default {
  cursor: default;
}

.step-wrapper {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
}

.steps {
  display: flex;
  justify-content: center;
}

.step {
  background-color: #fff;
  position: relative;
  color: var(--color-1);
  display: flex;
  flex-direction: column;
  align-items: center;
}

.step-active {
  color: var(--color-2);
}

.step-active .step-count div {
  background-color: var(--color-2);
  color: #fff;
}

.step-inactive .step-count div {
  background-color: var(--color-1);
  color: #fff;
}

.step-count {
  position: relative;
  z-index: 2;
  display: flex;
  flex-direction: column;
  margin-left: 1rem;
  margin-right: 1rem;
  margin-bottom: 0.8rem;
  transition: scale 0.3s;
}

.step-count div {
  border: 1px solid currentColor;
  border-radius: 0.5rem;
  width: var(--step-count-size);
  height: var(--step-count-size);
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 1rem;
}

.step-text {
  width: 4rem;
  max-height: 4rem;
  display: flex;
  justify-content: center;
}

.step-text p {
  font-size: 0.8rem;
  text-align: center;
  color: #000;
}

/* buttons */

.step-btn {
  --size: 1.6rem;
  color: #899499;
  border: 0;
  outline: none;
  background: transparent;
   width:  25px;    /*var(--size); */
  height: var(--size);
  position: absolute;
  top:  calc(var(--step-count-size) / 2);
  transform: translateY(-50%);
  cursor: pointer;
  z-index: 100;
  padding: 0;
}

.step-btn-left {
  left: -2rem;
}
.step-btn-right {
  right: -2rem;
}

.step-btn svg {
  width: 100%;
  height: 100%;
}

.seperator {
  width: 2rem;
  height: 1px;
  margin-top: calc(var(--step-count-size) / 2);
  position: relative;
  z-index: 1;
}

.seperator :where(.unfilled, .filled) {
  position: absolute;
  width: calc(100% + 2rem);
  top: 50%;
  left: 50%;
  transform: translate(-50%);
  height: 100%;
}

.seperator .unfilled {
  z-index: 1;
  background-color: var(--color-1);
}

.seperator .filled {
  z-index: 2;
  background-color: var(--color-2);
  transform-origin: left;
  transform: translate(-50%) scaleX(0);
  animation: expandWidth 0.3s linear forwards;
}

.bubble {
  animation: bubble 0.5s linear forwards;
}

@keyframes expandWidth {
  to {
    transform: translate(-50%) scaleX(1);
  }
}

@keyframes bubble {
  0% {
    transform: scale(1);
  }
  33% {
    transform: scale(1.1);
  }
  66% {
    transform: scale(0.95);
  }
  100% {
    transform: scale(1.1);
  }
}

@media (width > 640px) {
  .step-btn {
    --size: 2rem;
  }
  .seperator {
    width: 3.5rem;
  }

  .step-progress {
    --step-count-size: 3.5rem;
  }

  .step-count div {
    font-size: 1.5rem;
  }

  .step-text p {
    font-size: 1rem;
    margin: 0;
  }
}
